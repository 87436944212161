import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaCameraRetro } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WebNew = ({ data }) => {
  const seoData = {
    title: '動画制作・写真撮影｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      'Webサイト向けのプロフェッショナルな動画・写真を制作し、視覚的に強い訴求力を持つコンテンツを提供。ブランドの魅力を引き出し、ユーザーの関心を惹きつけるビジュアルを創り上げます。',
    pageUrl: 'https://www.involve-in.jp/service/media',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: '動画制作・写真撮影', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      {/* ✅ 構造化パンくずデータを渡す */}
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="動画制作・写真撮影"
        subtitle="魅力を最大限に伝える"
        icon={FaCameraRetro}
        description="Webサイト向けのプロフェッショナルな動画・写真を制作し、視覚的に強い訴求力を持つコンテンツを提供。ブランドの魅力を引き出し、ユーザーの関心を惹きつけるビジュアルを創り上げます。"
        seo={{
          title: '動画制作・写真撮影｜奈良のホームページ制作なら｜インヴォルブ',
          description:
            'Webサイト向けのプロフェッショナルな動画・写真を制作し、視覚的に強い訴求力を持つコンテンツを提供。ブランドの魅力を引き出し、ユーザーの関心を惹きつけるビジュアルを創り上げます。',
          pageUrl: 'https://www.involve-in.jp/service/media',
          ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
        }}
        breadcrumbs={[
          { name: 'ホーム', url: 'https://www.involve-in.jp/' },
          {
            name: 'サービス一覧',
            url: 'https://www.involve-in.jp/service/',
          },
          {
            name: '動画制作・写真撮影',
            url: 'https://www.involve-in.jp/service/media',
          },
        ]}
        benefits={[
          {
            title: '目的に合わせた撮影',
            description:
              '商品・サービス・企業ブランディングなど、用途に応じた最適な映像・写真を制作。',
          },
          {
            title: '高品質なビジュアル表現',
            description:
              'プロの機材と撮影技術を活かし、視覚的に訴求力の高いコンテンツを提供。',
          },
          {
            title: 'Web最適化編集',
            description:
              'WebサイトやSNSで効果的に活用できるよう、最適なフォーマット・サイズで納品。',
          },
        ]}
        flow={[
          {
            title: '企画・ヒアリング',
            duration: '1週間',
            description:
              '撮影の目的やターゲットを明確にし、シナリオやコンセプトを設計。撮影内容や必要な素材を整理。',
          },
          {
            title: '撮影・収録',
            duration: '1〜2日',
            description:
              'プロの機材を使用し、現場での撮影やスタジオ撮影を実施。被写体やライティングを調整しながら最適なカットを撮影。',
          },
          {
            title: '編集・加工',
            duration: '2週間',
            description:
              '撮影した素材を編集し、カット調整・カラー補正・テロップ追加・BGM挿入などを行い、クオリティの高い仕上がりに。',
          },
          {
            title: '納品・活用サポート',
            duration: null,
            description:
              'WebサイトやSNSでの活用に適したフォーマットで納品。必要に応じて追加調整や運用のアドバイスも提供。',
          },
        ]}
        preparation={[
          {
            title: '撮影の目的を明確に',
            description:
              '商品PR、企業ブランディング、採用動画など、どのような目的で活用するかを整理。',
          },
          {
            title: '撮影場所・シチュエーションの確認',
            description:
              'スタジオ撮影かロケ撮影か、必要な場所やシチュエーションを事前に決定。',
          },
          {
            title: '必要な素材・小道具の準備',
            description:
              '撮影に必要な商品・衣装・小道具・台本などを用意し、スムーズな撮影を実現。',
          },
          {
            title: '納品形式・用途の確認',
            description:
              'Webサイト・SNS・YouTubeなど、使用する媒体に応じた最適なフォーマット・解像度で納品。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null, // 🔥 最初の画像のみ取得
        }))}
        price="¥150,000〜"
        cta="まずは無料相談"
      />
      {/* ✅ 表示用のパンくずは ServiceTemplate で管理 */}
    </>
  );
};

// GraphQL クエリ（Contentful の施工事例を取得）
export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WebNew;
